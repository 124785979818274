import React, {useCallback, useEffect, useMemo, useRef} from 'react';

import {Link, useLocation} from 'react-router-dom';

import {CONSTANT_ROUTER} from '@/dicts/router';

import baiduLogo from '@/assets/home/bg_baidulogo_190.png';
import NavLink from './NavLink';

import c from './index.less';

export default function Header() {
    const headerRef = useRef<HTMLDivElement>(null);
    const location = useLocation();

    const scrollCall = useCallback(
        () => {
        // 获取滚动距离
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            if (headerRef.current) {
                if (scrollTop > 0) {
                // 设置 Header 背景色为黑色
                    headerRef.current.style.backgroundColor = '#000';
                } else {
                // 设置 Header 背景色为蓝色（回到最顶部）
                    headerRef.current.style.backgroundColor = 'transparent';
                }
            }
        },
        []
    );

    // 判断有些页面是不需要背景透明的
    const isBgcNeedBlack = useMemo(
        () => {
            window.scrollTo({
                top: 0,
            });

            return [
                '/policy/PrivacyPolicy',
                '/policy/children-privacy-policy/child',
                '/resourceCenter/whiteBook',
                '/organization/reports',
                '/detail',
            ].includes(location.pathname);
        },
        [location.pathname]
    );

    useEffect(
        () => {
            if (!isBgcNeedBlack) {
            // 监听页面滚动事件
                window.addEventListener('scroll', scrollCall);
                scrollCall();
            }

            return () => {
                window.removeEventListener('scroll', scrollCall);
            };
        },
        [scrollCall, isBgcNeedBlack]
    );

    return (
        <div className={c('root', {bgcBlack: isBgcNeedBlack})} ref={headerRef}>
            <div className={c.leftLogo}>
                <Link to="/"><img src={baiduLogo} alt="Baidu信任中心" /></Link>
            </div>
            <ul className={c.rightMenu}>
                <NavLink configs={CONSTANT_ROUTER} />
            </ul>
        </div>
    );
}
